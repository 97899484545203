import React, {Component} from 'react';
import ReactDOM from 'react-dom';

var _ = require('lodash');
var d3 = require('d3');

console.log('my d3d: ', d3, d3.time)

function padValue(text, count) {
  var result = _.padStart(text, count, "_").replace(/\_/g, "&nbsp;");
 // console.log('padding value: ', text, count, result)

  return result;
}

const ColorTable = [
  '#1485CC', '#FF0000', 'orange', '#54CC14', 'purple', 'magenta', 'green', 'blue',
  "#E82C0C", "#E80C7A",  "#FFC70D", "#71FF03", "#02B6E8", "#BA0AFF"
];

// default legend component for all charts
var LegendComponent = (props) => {
  return  <div className='legend-container' style={{width: '100%', height: '24px', top: 0, position: 'absolute', zIndex: 2}}>
    <span key={-1} style={{fontWeight: 'normal', float: 'left', marginLeft: 25}}>
      [{props.title}]
    </span>
    {
      props.series.map((r, i) => {
        // var legendString =  (props.legendSize == "full" ? r.legendName : r.shortLegendName).substring(1).replaceAll('_', '.').split('.')[0];
        var legendName =  (props.legendSize == "full" ? r.legendName : r.shortLegendName).substring(1).replaceAll('_', '.');
        var legendString =  [ legendName.split('.')[0], mapParTypeName(legendName.split('.')[1])].join('.');
        // var legendString =  (props.legendSize == "full" ? r.legendName : r.shortLegendName)

        //console.log('my legend: ', r.legendName, r.shortLegendName)
        return <div key={i} className='legend-item' style={{float: 'right', marginRight: 5, fontSize: 'x-small'}}>
          <div key={0} style={{width: 7, float: 'left', borderRadius: 10, height: 7, marginTop: 5, marginRight: 3,background: r.style.stroke, boxSizing: 'border-box'}}>
          </div>
          <div key={1} style={{float: 'left'}}><span style={{}} dangerouslySetInnerHTML={{__html: legendString + ":"}}/></div>
          <div key={2} style={{marginLeft: 1, marginRight: 1, float: 'left', minWidth: '6ex', textAlign: 'right'}}><span className='legend-item-value' style={{minWidth: '4ex'}}
            dangerouslySetInnerHTML={{__html: padValue(props.valueFormatter ? props.valueFormatter(r.valueAccessor(r.data[r.data.length - 1])) : r.valueAccessor(r.data[r.data.length - 1]), 5)}}/>
          </div>
          {props.legendSize == "full" &&
          <div key={3} style={{marginLeft: 1, marginRight: 1, float: 'left'}}>
            {mapUnitNameToTag('ug/m3', 3)}
          </div>
          }
        </div>
      })
    }
    <div key={-2} className='legend-date' style={{fontWeight: 'normal',fontSize: 'x-small',  width: '16ex', background: '#fefefe', color: 'black', display: 'block', position: 'absolute', textAlign: 'center', height: '1em', lineHeight: '1em'}}>
    </div>
  </div>;
};

//var d3formatter = d3.time.format('%Y');
var d3formatter = d3.format('%Y');

// tick formatter
function tickFormatter(date) {
  var val = d3formatter(date);

  return (val - 2000) + "%";
}


// mapping par type name to tag
function mapParTypeNameToTag(type, key) {
  return <span key={key || 0} dangerouslySetInnerHTML = {{__html: mapParTypeName(type)}}/>
}

// mapping par type name to html
function mapParTypeName(type) {
  switch (type) {
    case 'Benzen': return 'C<sub>6</sub>H<sub>6</sub>'
    case 'PID': return 'LZO';
    case 'O3':
    case 'Ozon': return 'O<sub>3</sub>';
    case 'NO2': return 'NO<sub>2</sub>';
    case 'SO2': return 'SO<sub>2</sub>';
    case 'NOx':
    case 'NOX': return 'NO<sub><small>x</small></sub>';
    case 'PM2.5':
    case 'PM25': return 'PM<sub>2.5</sub>';
    case 'PM10': return 'PM<sub>10</sub>';
    case 'PM1': return 'PM<sub>1</sub>';
    default: return type;
  }
}

// maping unit name to span tag
function mapUnitNameToTag(type, key) {
  return <span key={key || 0} dangerouslySetInnerHTML = {{__html: mapUnitName(type)}}/>
}


// mapping unit name to html
function mapUnitName(type) {
  switch (type) {
    case 'ug/m3':
    case 'µg/m3': return "µg/m<sup>3</sup>";
    case 'm3/s': return 'm<sup>3</sup>/s';
    case 'm3': return 'm<sup>3</sup>';
    case 'uS/cm': return 'µS/cm';
    case 'ug/l': return 'µg/l';
    default: return type;
  }
}


function decimals(value, decimals) {
  return Number(Math.round(value+'e'+decimals)+'e-'+decimals);
}

export {
  mapUnitName, mapUnitNameToTag, mapParTypeName, mapParTypeNameToTag, LegendComponent, ColorTable, decimals
}
